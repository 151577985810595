.notifications_container{
    color: var(--componentTextColour);
    font-size: var(--componentTextSize);
}

.notifications_row{
    display: grid;
    grid-template-columns: 50px 35% 45%;
    grid-gap: 5px;  
    align-items: center;
    padding: 5px;
    border-radius: 6px;
    background-color: gray;
    color: white;
    
}

.notifications_Battery{
    background-color: #5DCC8E;
}


.monitor_test_calls_container {

    max-height: 200px;
    overflow-y: auto;
    border: gray 1px solid;
    border-radius: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.test_call_card_container {
    padding: 10px;
    border: gray 1px solid;
    border-radius: 6px;
    background-color: gray;
    color: white;
    display: grid;
    grid-template-columns: 20% 45% 35%;
    grid-gap: 5px;
    margin-bottom: 5px;
    align-items: center;

}

.monitor_test_calls_card {
    max-width: 350px;
}